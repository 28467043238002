@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#methods {
    display: inline-block;
    text-align: center;
    width: 100%;
    margin-bottom: 4%;
    animation: fadeIn 3s;
}

.btngrad {
    display: inline-block;
    background-image: linear-gradient(to right, #000428 0%, #0a66c2 51%, #000428 100%);
    margin: 10px;
    padding: 5px 25px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 10px #eee;
    border-radius: 10px;
    width: 115px;
    position: relative;
    animation: fadeIn 3s;
    outline: none;
}

.btngrad:disabled {
    display: inline-block;
    background-image: linear-gradient(to right, black 0%, gray 51%, black 100%);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2rem;
    margin: 10px;
    padding: 5px 25px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 10px #eee;
    border-radius: 10px;
    width: 115px;
    position: relative;
    animation: fadeIn 3s;
    outline: none;
}

.btngrad:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.resetBtn:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.resetBtn {
    display: inline-block;
    background-image: linear-gradient(to right, #2b0000 0%, #FF0000 51%, #2b0000 100%);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2rem;
    margin: 10px;
    padding: 5px 25px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 10px #eee;
    border-radius: 10px;
    width: 115px;
    position: relative;
    animation: fadeIn 3s;
    outline: none;
    cursor: pointer;
    z-index: 120;
}

#linkedin {
    display: inline-block;
    animation: fadeIn 3s;
}

#email {
    display: inline-block;
    background-image: linear-gradient(to right, #F7971E 0%, #FFD200 51%, #F7971E 100%);
    color: black;
    animation: fadeIn 3s;
}

#github {
    display: inline-block;
    background-image: linear-gradient(to right, #834d9b 0%, #d04ed6 51%, #834d9b 100%);
    animation: fadeIn 3s;
}

#submitButton:disabled {
    background-image: linear-gradient(to right, black 0%, gray 51%, black 100%);
}

#submitButton {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2rem;
    background-image: linear-gradient(to right, #2b0000 0%, #00FF00 51%, #2b0000 100%);
}

#submitButton, #resetButton {
    width: 165px;
    height: 35.5px;
    animation: fadeIn 3s;
    border: none;
}

.message {
    text-align: center;
    animation: fadeIn 3s;
}

#contactme {
    margin-bottom: 4%;
    animation: fadeIn 3s;
}

#contactForm {
    text-align: center;
    animation: fadeIn 3s;
}

#fullName,
#emailadd,
#subject {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 40%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-sizing: border-box;
    margin-top: 15px;
    z-index: 92;
    position: relative;
    animation: fadeIn 3s;
}

#fullName_mobile,
#emailadd_mobile,
#subject_mobile {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 90%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-sizing: border-box;
    margin-top: 15px;
    z-index: 92;
    position: relative;
    animation: fadeIn 3s;
}

textarea {
    min-height: 200px;
    min-width: 40%;
    animation: fadeIn 3s;
}

.formBtns {
    position: relative;
    z-index: 100;
    animation: fadeIn 3s;
}

#errors {
    text-align: center;
}

.hide_error {
    display: none;
}