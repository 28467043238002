a,
button {
  position: relative;
  cursor: pointer;
  z-index: 100;
}

h1,
p {
  position: relative;
  z-index: 90;
  padding-left: 2%;
}

.message {
  text-align: center;
  position: relative;
  animation: fadeIn 3s;
}

#main {
  margin-top: 7%;
}

#main_mobile {
  width: 80%;
  margin: 0 auto;
}

#tldr {
  padding-top: 7%;
  text-align: center;
  width: 60%;
  margin: 0 auto;
  animation: fadeIn 3s;
}

#tldr_mobile {
  padding-top: 1%;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 100px;
  animation: fadeIn 3s;
}

#tldr_tablet {
  padding-top: 1%;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 100px;
  margin-right: 50px;
  animation: fadeIn 3s;
}

#tldrHeading {
  font-weight: 500;
}

.headshot {
  border-radius: 50%;
  height: 250px;
  text-align: center;
  position: relative;
  margin-left: 2em;
}

.headshot_mobile {
  border-radius: 50%;
  height: 200px;
}

.headshot_tablet {
  border-radius: 50%;
  height: 187px;
  margin-top: 2em;
  margin-left: 4em;
}

#left_mobile {
  display: inline-block;
}

#right {
  display: inline-block;
  width: 60%;
}

#right_mobile {
  display: inline-block;
  width: 90%;
}

.fact {
  text-align: left;
}

.fact_mobile {
  padding-left: 0%;
  text-align: center;
}