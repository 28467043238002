.Footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 50px;
    text-align: center;
}

.relFooter {
    position: relative;
    width: 100%;
    bottom: 0;
    height: 50px;
    text-align: center;
    margin-top: 20px;
}