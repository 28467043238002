.About {
    text-align: center;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

ul, li {
    z-index: 85;
    position: relative;
    left: 0.5%;
}

#nitish {
    z-index: 86;
    width: 20%;
    border-radius: 12px;
    margin-left: 14%;
    animation: fadeIn 3s;
}

#nitish_mobile {
    float: none;
    width: 50%;
    margin: 0 auto;
    border-radius: 12px;
    animation: fadeIn 3s;
}

.pic_mobile {
    text-align: center;
    animation: fadeIn 3s;
}

.parent {
    width: 100%;
    overflow: hidden;
    margin-top: 4%;
    margin-bottom: 6%;
}

.parent_mobile {
    width: 100%;
    overflow: hidden;
    margin-top: 4%;
    margin-bottom: 20%;
}

.paragraph {
    width: 50%;
    margin-right: 12%;
    float: right;
    position: relative;
    display: block;
    margin-top: 0;
    animation: fadeIn 3s;
}

.paragraph_mobile {
    float: none;
    margin: 4% auto;
    width: 70%;
    text-align: center;
    animation: fadeIn 3s;
}

#list {
    margin-top: 0;
    width: 50%;
    margin-right: 12%;
    float: right;
    position: relative;
    display: block;
    animation: fadeIn 3s;
}

#list_mobile {
    float: none;
    margin: 4% auto;
    width: 70%;
    animation: fadeIn 3s;
}

.aboutMe {
    position: relative;
}

.aboutMe_mobile {
    margin-top: 4%;
    width: 100%;
}

.aboutMe p {
    margin-bottom: 30px;
    animation: fadeIn 3s;
}