@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.pgLink_mobile, .pgLink_mobile:visited {
    text-decoration: none;
    font-weight: bold;
    color: orange;
    text-align: right;
    display: block;
    margin-top: 20px;
}

.blog_header, .blog_content {
    text-align: center;
    width: 55%;
    margin: 0 auto;
    animation: fadeIn 3s;
    cursor: text;
    pointer-events: auto;
}

.blog_header_mobile, .blog_content_mobile {
    text-align: center;
    width: 75%;
    margin: 0 auto;
    animation: fadeIn 3s;
    cursor: text;
    pointer-events: auto;
}

.blog_content {
    margin-top: 3%;
}

.title {
    text-align: left;
    padding-left: 0;
    margin-bottom: 1%;
}

.subtitle, .published, .paragraph {
    text-align: left;
    font-weight: normal;
    margin-top: 2%;
    padding-left: 0;
}

.heading {
    text-align: left;
}

.subheading {
    text-align: left;
    font-weight: bold;
    margin-bottom: 2%;
}

.img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    float: none;
    margin-bottom: 2%;
}

.img_mobile {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    float: none;
    margin-bottom: 2%;
    width: 100%;
}

.caption {
    margin-top: 2%;
    font-weight: normal;
    position: relative;
    z-index: 110;
}

.ending {
    text-align: right;
    margin-bottom: 0.5%;
}

.ending:last-child {
    margin-top: 0;
}

.list {
    text-align: left;
}

.action {
    margin-bottom: 2%;
}

code {
    z-index: 130;
    position: relative;
    margin-bottom: 2%;
}