.App {
  text-align: center;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.darkgradient {
  background: #1f1f1f;
  font-size: 1.2rem;
  border: none;
  color: white;
  outline: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: background, color;
  transition-duration: 2s;
}

.lightgradient {
  background: #f4f4f4;
  font-size: 1.2rem;
  border: none;
  color: black;
  outline: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: background, color;
  transition-duration: 2s;
}

.darkgradient_mobile {
  background: #1f1f1f;
  font-size: 1.2rem;
  border: none;
  color: white;
  outline: none;
  overflow: hidden;
  width: 100%;
  height: auto;
  position: relative;
  overflow-y: auto;
  transition-property: background, color;
  transition-duration: 2s;
}

.lightgradient_mobile {
  background: #f4f4f4;
  font-size: 1.2rem;
  border: none;
  color: black;
  outline: none;
  overflow: hidden;
  width: 100%;
  height: auto;
  position: relative;
  overflow-y: auto;
  transition-property: background, color;
  transition-duration: 2s;
}

.darkgradient:before,
.darkgradient_mobile:before {
  --size: 1500px;
  content: '';
  position: absolute;
  left: var(--x);
  top: var(--y);
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #414141, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.1s ease, height 0.1s ease, background 2s;
}

.lightgradient:before,
.lightgradient_mobile:before {
  --size: 1500px;
  content: '';
  position: absolute;
  left: var(--x);
  top: var(--y);
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #dbd8d8, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.1s ease, height 0.1s ease, background 2s;
}

.darkgradient:hover:before, .lightgradient:hover:before {
  --size: 1500px;
}

#header {
  text-align: center;
  margin-top: 4%;
  z-index: 90;
  animation: fadeIn 3s;
}

#subheader {
  text-align: center;
  z-index: 91;
  margin-bottom: 4%;
  animation: fadeIn 3s;
}

a, button {
  position: relative;
  cursor: pointer;
  z-index: 100;
}

h1, p {
  position: relative;
  z-index: 90;
  padding-left: 2%;
}

h2, h3, h4 {
  z-index: 91;
  position: relative;
}

#message {
  margin-top: 240px;
}

.pgLink, .pgLink:visited {
  text-decoration: none;
  font-weight: bold;
  color: orange;
  text-align: center;
}

.pgLink:hover {
  color: green;
}