.Navbar_mobile {
    position: static;
    overflow: hidden;
}

.Navbar {
    position: relative;
    overflow: hidden;
}

.linksdark {
    background-color: #393838;
    overflow: hidden;
    float: none;
    position: absolute;
    top: 45%;
    left: 51%;
    transform: translate(-50%, -50%);
    width: 910px;
    border-radius: 50px;
    border: 3px #2f2f32 solid;
    transition-property: background-color, border;
    transition-duration: 2s;
}

.linkslight {
    background-color: #ffffff;
    overflow: hidden;
    float: none;
    position: absolute;
    top: 45%;
    left: 51%;
    transform: translate(-50%, -50%);
    width: 910px;
    border-radius: 50px;
    border: 2px #eeeeee solid;
    transition-property: background-color, border;
    transition-duration: 2s;
}

.linksdark p, .linkslight p {
    float: left;
    margin-left: 25px;
    transition: none;
}

.toggleSelect {
    float: left;
    margin-left: 25px;
}

.linksdark p:hover, .linkslight p:hover {
    color: cornflowerblue;
    cursor: pointer;
    transition: none;
}

.toggle {
    float: right;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 30px;
    position: relative;
}

#Nitish {
    font-weight: bold;
    font-size: 28px;
    margin-top: 14px;
    margin-bottom: 14px;
    animation: none;
}

.link {
    animation: none;
}

.link_mobile {
    margin-left: 10px;
}

.selected {
    font-weight: bold;
    color: cornflowerblue;
    text-decoration: none;
}

.normaldark {
    color: white;
    text-decoration: none;
    transition-property: color;
    transition-duration: 2s;
    animation: none;
}

.normallight {
    color: black;
    text-decoration: none;
    transition-property: color;
    transition-duration: 2s;
    animation: none;
}

.normaldark:hover, .normallight:hover {
    color: cornflowerblue;
    cursor: pointer;
    transition-duration: 0.5s;
}

.dropbtn_dark {
    background-color: #393838;
    color: white;
    padding: 8px 16px;
    font-size: 28px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    border: 5px #2f2f32 solid;
    cursor: pointer;
}

.dropbtn_light {
    background-color: #ffffff;
    color: black;
    padding: 8px 16px;
    font-size: 28px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    border: 5px #eeeeee solid;
    cursor: pointer;
}

.dropdowncontent {
    position: fixed;
    background-color: black;
    left: 50%;
    z-index: 110;
    opacity: 0;
    transition: opacity 1s, background-color 2s;
    margin-left: -38.5%;
    width: 75%;
}

.dropdowncontent_light {
    position: fixed;
    background-color: lightgray;
    left: 50%;
    z-index: 110;
    opacity: 0;
    transition: opacity 1s, background-color 2s;
    margin-left: -38.5%;
    width: 75%;
}

.start {
    text-align: center;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    transition: .4s;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
}

.tooltipText {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    position: absolute;
    z-index: 1;

    top: 2px;
    right: 105%;
}

.label_left {
    position: relative;
    top: -14px;
    left: -17px;
}

.label_right {
    position: relative;
    top: -14px;
    left: 17px;
}

.toggle_mobile {
    text-align: center;
}

.toggle:hover .tooltipText {
    visibility: visible;
}

#icon {
    margin-top: 2px;
    margin-right: 10px;
    animation: none;
}