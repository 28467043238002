@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.number {
    font-weight: bold;
    font-size: 48px;
}

.fact {
    text-align: center;
    font-weight: normal;
    animation: fadeIn 3s;
}

.destList {
    margin: 0 auto;
    width: 40%;
    margin-top: 100px;
    padding-bottom: 50px;
    animation: fadeIn 3s;
}

.destList_mobile {
    margin: 0 auto;
    width: 75%;
    margin-top: 100px;
    padding-bottom: 50px;
    animation: fadeIn 3s;
}

.pic {
    margin-right: 10px;
    width: 100%;
    animation: fadeIn 3s;
}

.row {
    display: flex;
    justify-content: center;
    column-gap: 15px;
}

.column {
    flex-grow: 1;
}

table {
    text-align: center;
    margin: 0 auto;
    border-spacing: 20px 0;
    position: relative;
    z-index: 120;
    animation: fadeIn 3s;
}

tr {
    margin-right: 20px;
}

.maps {
    margin: 0 auto;
    width: 50%;
}

.world {
    width: 50%;
    float: left;
}

.america {
    margin-left: 50%;
}