.Resume {}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

.header {
    text-align: center;
    width: 100%;
    margin-top: 3%;
    margin-bottom: 2%;
    /* animation: fadeIn 3s; */
}

#updated_resume_ux {
    margin: 0 auto;
    width: 85%;
    /* animation: fadeIn 3s; */
    padding-bottom: 20px;
}

.dark_resume {
    background-color: black;
    transition-property: background-color;
    transition-duration: 2s;
}

.light_resume {
    background-color: lightgray;
    transition-property: background-color;
    transition-duration: 2s;
}

.navbar {
    position: relative;
}

.left {
    background-color: transparent;
    border: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 120;
}

.right {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 120;
}

.resume_tabs {
    position: relative;
    z-index: 115;
    overflow: hidden;
    background-color: black;
}

.resume_tabs_portrait {
    position: relative;
    z-index: 115;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: visible;
    overflow-y: hidden;
    background-color: black;
}

.resume_tabs button {
    background-color: gray;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    width: 16.66667%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
}

.resume_tabs_portrait button {
    background-color: gray;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
}

.resume_tabs button:hover {
    background-color: darkgray;
}

.button_selected {
    font-weight: bold;
}

#resume_container {
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    width: 85%;
    /* animation: fadeIn 3s; */
}

#col1,
#col2 {
    width: 40%;
}

.title {
    text-align: left;
    margin-bottom: 5px;
    font-weight: 500;
}

.heading {
    text-align: center;
}

h4 {
    text-align: left;
    font-weight: normal;
    margin-top: 0px;
    margin-bottom: 0px;
}

ul {
    text-align: left;
}

ul:last-of-type {
    margin-bottom: 20px;
}

.resume_tab_content {
    animation: fadeIn 3s;
}

.experience, .edu, .courses, .skills, .awards, .orgs {
    margin: 20px;
}

#courses {
    text-align: left;
}

#fulllist {
    width: 160px;
    margin: 0 auto;
}

.btngrad {
    background-image: linear-gradient(to right, #000428 0%, #000099 51%, #000428 100%);
    margin: 10px;
    padding: 5px 25px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 10px #eee;
    border-radius: 10px;
    display: block;
    width: 115px;
    position: relative;
    margin-left: 300px;
    /* animation: fadeIn 3s; */
}

.btngrad_mobile {
    background-image: linear-gradient(to right, #000428 0%, #000099 51%, #000428 100%);
    margin: 10px;
    padding: 5px 25px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 10px #eee;
    border-radius: 10px;
    display: block;
    width: 115px;
    position: relative;
    margin-left: 40px;
    /* animation: fadeIn 3s; */
}

.btngrad:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

#orgs,
#skills,
#awards {
    margin-top: 10%;
    /* animation: fadeIn 3s; */
}