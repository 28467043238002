@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

#essayList {
    text-align: center;
    margin: 0 auto;
    display: block;
    width: 25%;
    padding-bottom: 250px;
    animation: fadeIn 3s;
}

#essayList_mobile {
    text-align: center;
    margin: 0 auto;
    display: block;
    width: 90%;
    padding-bottom: 250px;
    animation: fadeIn 3s;
}

#essayList_landscape {
    text-align: center;
    margin: 0 auto;
    display: block;
    width: 50%;
    padding-bottom: 250px;
    animation: fadeIn 3s;
}