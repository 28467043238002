.EssayLink_dark {
    background-color: #2C2C2C;
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    border-radius: 6px;
    z-index: 100;
    position: relative;
    box-shadow: 0 0 10px #eee;
    color: white;
    transition: background-color 2s, color 2s, box-shadow 2s;
}

.EssayLink_light {
    background-color: #F4F4F4;
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    border-radius: 6px;
    z-index: 100;
    position: relative;
    box-shadow: 0 0 10px #001;
    color: black;
    transition: background-color 2s, color 2s, box-shadow 2s;
}

.essay_title {
    width: 360px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
    padding-top: 0.5em;
    text-align: left;
    margin-bottom: 0.5em;
    margin-left: 10px;
}

.essay_highlight {
    text-align: left;
    margin-left: 10px;
}

#sep {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.date {
    margin-top: 0;
    margin-bottom: 0.5em;
}

.read {
    background-image: linear-gradient(to right, #000428 0%, blue 51%, #000428 100%);
    padding: 5px 25px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 10px #eee;
    border-radius: 10px;
    width: 115px;
    outline: none;
    border: none;
    font-size: 1.2rem;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom: 70px;
}

.read:hover {
    background-position: right center;
    text-decoration: none;
    cursor: pointer;
}